<template>
    <footer>
        <p style="font-size:12px;padding-top:100px;">services provided internally by <a href="https://spce.moe" target="_blank">spce.moe</a></p>
        <p style="font-size:12px;">(c) aya / aya.so</p>
    </footer>
</template>

<style scoped>
p {
    color: #ffefe0;
}
a {
  transition: 0.5s;
}
a:hover {
  color: #6d6d6d;
  transition: 0.5s;
}
</style>