<template>
  <router-view/>
</template>

<script>
export default {
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'aya.so';
    }
  },

  mounted() {
    var data = '<!--彡★彡☆ dzn by spce.moe 彡★彡☆彡-->'
    document.body.insertAdjacentHTML('beforeend', data);
  }
}
</script>

<style>
body {
  background-color: #1d1d1f;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffefe0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
