<template>
  <img src="@/assets/t2.png" style="margin-top: 150px;object-fit:scale-down;margin-left:auto;margin-right:auto;">
  <div class="home">
    <div class="t"></div>
    <router-link to="/"><img src="@/assets/logo2.png" style="max-width:200px;margin-left:auto;margin-right:auto;"></router-link>
    <div class="p-8 pe">
      <h1 style="letter-spacing: 1px;font-size: 18px;margin-bottom:20px;padding-left:30px;padding-right:30px;">aya is a invite-only collection of free-to-use applications.</h1>

      <div class="grid grid-cols-3 pt-8 pb-7">
        <div>
          <h1><router-link to="/apply">[ apply ]</router-link></h1>
        </div>
        <div>
          <h1><router-link to="/login">[ enter ]</router-link></h1>
        </div>
        <div>
          <h1><router-link to="/status">[ status ]</router-link></h1>
        </div>
      </div>
      <img src="@/assets/aya2.png" style="position:absolute;top:500px;max-width:150px;margin-left:500px;">
    </div>
  </div>

  <Footer />
</template>

<script>
import Footer from '@/components/FooterA.vue';

export default {
  name: 'HomeView',
  components: {
    Footer,
  }
}
</script>

<style scoped>
img {
  pointer-events: none;
}
.pe {
  padding-left: 20px;
  padding-right: 20px;
}
.t {
  background-image: url('@/assets/t.png');
  position: absolute;
  height: 500px;
  width: 20px;
  background-repeat: no-repeat;
}
.home {
  background-color: #262629;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: auto;
  max-width: 600px;
  border: 1px solid #7a636a;
}

</style>
